.documentwindow {
    text-align: center; 
    width: 100%; 
    max-width: 100vh; 
} 


.downloadicondiv {
    width: 100vw; 
    left: 0; 
}