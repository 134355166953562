
#homePageImage {
  display: flex; 
  align-items: flex-start; 
  margin: auto; 
  width: 100vw; 
  height: 100vh;
  text-align: center;
  justify-content: center;
  margin-top: 4px;
}
