.abouttext {
  column-count: 1; 
  column-gap: 5vw; 
  margin-top: 4vh;
  margin-left: 18vw; 
  margin-right: 18vw; 
  text-align: justify; 
}

.aboutparagraph { 
  margin-bottom: 10px; 
}

.papaypicture {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: inline-block;
  text-align: center;
}

.topHeader {
  height:10px;
  width: 100%;
}

@media only screen and (orientation: landscape) and (min-width: 1050px) and (max-width: 1250px) {
  .abouttext {
    column-count: 2;
  }

  .topHeader {
    height: 30px; 
  }
}  

@media only screen and (orientation: landscape) and (min-width: 1250px) and (max-width: 1450px) {
  .abouttext {
    column-count: 2;
  }

  .topHeader {
    height: 30px; 
  }
}  

@media only screen and (orientation: landscape) and (min-width: 1450px) {
  .abouttext {
    column-count: 2;
  }

  .topHeader {
    height: 30px; 
  }
}  
