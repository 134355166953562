.topDiv {
    margin-top: 3vh;
}

.vimeoVideo {
    padding: 40% 0 0 0;
    position: relative; 
    max-width: 50%;
    max-height: 50%; 
    margin: auto;
}

@media only screen and (orientation: portrait) {
    .vimeoVideo {
        max-width: 90%; 
        max-height: 90%; 
    }
}   
