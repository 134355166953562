@font-face
{
font-family: GeoSansLight;
src: url('../fonts/GeosansLight.ttf'),
url('../fonts/GeosansLight.eot');
}

.test {
  color: white!important;
  z-index: 100; 
  width: 30px; 
  height: 30px; 
  border-color: transparent; 
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")!important;
}

.nav-link {
  font-size: 15px !important; 
}

.navbar-light, .light-nav {
  color: white!important; 
  border-color: transparent!important;  
}

.navbar-light .navbar-toggler {
  border-color: transparent!important;
}

.navbar-light .navbar-toggler:focus {
  outline:none!important; 
}


@media only screen and (max-width: 800px) {
  .navbar-collapse.collapse.show {
    background-color: rgba(255,255,255,0.8);
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .navbar-collapse.collapsing {
    background-color: rgba(255,255,255,0.8);
    margin-left: -15px;
    margin-right: -15px;
  }


  .nav-item {
    margin-right: 20px;
  }
}   


.dropdown-menu {
  background-color: transparent!important;
  border-color: transparent!important; 
}

a {
  color: darkslategrey; 
  font-family: Helvetica Neue,sans-serif !important; 
  font-size: 16px; 
}
h1, h2, h3 {
  color: darkslategrey;
  font-family: Helvetica Neue,sans-serif !important;  
}

h2 {
  text-align: center;
  letter-spacing: 0.1em;
}

h1 {
  font-size: 2.0em; 
}

h3 {
  font-size: 1.3em; 
}

div {
  font-family: Helvetica Neue,sans-serif !important; 
  font-size: 16px; 
  color: darkslategrey;
}

.quietLink {
  color: darkslategrey; 
}
.quietLink:hover {
  color: black;
  text-decoration: none;
}

.headerLink {
  color: darkslategrey; 
  text-decoration: none !important;
  letter-spacing: 0.25em;
  font-size: 16px; 
}
.headerLink:hover {
  color: black; 
  text-decoration: none !important;
}

.heading {
  padding: 15px; 
}

.nav-link {
  letter-spacing: 2px; 
}

.sticky {
  position: sticky; 
  position: -webkit-sticky; 
  z-index: 2; 
  top: 0;
  height: 65px; 
  width: 100vw; 
  background-color: white!important; 
}

.content {
  z-index: 1;
  margin-top: 50px;
}

.sectionheader {
  width: 100vw; 
  position: absolute; 
  text-align: center; 
  font-size: 2rem; 
  margin-top: 15px;
  letter-spacing: 0.1em;
  left: 0; 
  background-color: white; 
  z-index: 1; 
  vertical-align: bottom;
  display: flex!important; 
  justify-content: center; 
  height: 10vh;
  max-height: 100px; 
}

.sectionheadernotext {
  width: 100vw; 
  position: absolute; 
  text-align: center; 
  font-size: 2rem; 
  margin-top: 15px;
  letter-spacing: 0.1em;
  left: 0; 
  color: black!important; 
  font-weight: 500; 
  background-color: white; 
  z-index: 1; 
  vertical-align: bottom;
  display: flex!important; 
  justify-content: center; 
  height: 45px; 
}


.downloadicon {
  min-width: 15px;
  width: 2vw;
  max-width: 28px;
  position: absolute; 
  margin-left: 3vw; 
  align-self: flex-end;  
}

.sectionheader span {
  align-self: flex-end;
}

@media only screen and (min-width: 800px) {
  .headerLink {
      font-size: 24px; 
  }
  .downloadicon {
    transform: translate(0px, -4px);
  }
}   

@media only screen and (max-width: 766px) {
  a {
    font-size: 12px; 
  }
  div {
    font-size: 12px;
  }

  .sectionheader {
    margin-top: 20px;
    max-height: 75px;
    font-size: 1.25rem;
  }
  
  .headerLink {
    font-size: 20px;
  }
  .content {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 350px) {
  .headerLink {
    font-size: 16px;
  }
}

@media only screen and (max-height: 799px) {
  .sectionheadernotext {
    height: 10px; 
  }
}