
#viewingimage {
    max-width: 80vw;
    max-height: 80vh;
    position: relative; 
}

#imagecontainer {
    width: 100vw; 
    height: 100vh;
}

#infocontainer {
    width: 100vw; 
    margin-bottom: 2vh;
    margin-top: 10px;
    text-align:right;
}

#infoicon {
    margin-right:49.4%;
    position: relative; 
    cursor: pointer; 
    width: 3vh; 
    min-width: 20px;
}

.icon {
    position: relative; 
    cursor: pointer; 
    width: 3vh; 
    min-width: 20px;
    margin-right: 12px; 
}

.hiddenImage {
    visibility: hidden; 
    height: 0px; 
    width: 0px;
}

.quietLinkIcon {
    text-decoration: none;
}

.quietLinkIcon:hover {
    text-decoration: none;
    color: inherit;
}

.directionarrows {
    cursor: pointer;
    position: relative;
    width: 2vw;
    min-width: 30px;
}

.directionarrowscontainercontainer {
    width: 10vw;
}

.directionarrowscontainer {
    display: flex; 
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.col-xl-6, .col-sm-12, .col-lg-12, .col-12, .col-md-12, .col-xs-12, .container {
    padding-right: 0px!important; 
    padding-left: 0px!important; 
}

.row {
    margin-left: 0px!important; 
    margin-right: 0px!important; 
}

.infotext {
    top: 5%;
    height: 40vh;
    text-align: center;
    color: darkslategrey; 

}

#imagewall {
    vertical-align: center; 
}

#frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.infoline {
    margin-bottom: 24px;
}

@media only screen and (orientation: portrait) {
    #frame {
         flex-direction: column;
    }
    .directionarrows {
        top: 25%;
    }
    .infoline {
        margin-bottom: 14px;
    }
    .viewingimage {
        max-width: 90vw;
    }
}

@media only screen and (orientation: landscape) {
    #frame {
         flex-direction: row;
    }
    .infotext {
        top: 40%;
        text-align: left;
        position: relative;
        width: 30vh; 
        padding-left: 15px;
    }
}

@media only screen and (max-width: 1999px) {
    #imagewall {
        vertical-align: text-top;
    }
}

@media only screen and (max-width: 1100px) {
    .infotext {
        margin-top: 3vh; 
    }
}

@media only screen and (max-height: 600px) {
    #infocontainer {
        margin-top: 10px;
    }
    #infoicon {
        margin-right: 48%;
    }
}  


@media only screen and (max-width: 800px) {
    #infocontainer {
        margin-top: 10px;
    }
    #infoicon {
        margin-right: 48%;
    }
}  

@media only screen and (orientation: landscape) and (max-height: 450px) {
    #frame {
        flex-direction: column; 
    }
    .infotext {
        top: 1%;
        width: 100%;
        height: 40vh;
        text-align: center;
        color: darkslategrey; 
    }
    
}

@media only screen and (min-width: 1200px) {

    #frame {
        flex-direction: row;
    }
    .infotext {
        top: 40%;
        text-align: left;
        position: relative;
        width: 30vh; 
    }
    
}    

@media only screen and (min-width: 1500px) {
    .infoviewingimage {
        align-self: center;
    }
} 

@media only screen and (min-width: 1800px) {
    .infoviewingimage {
        align-self: center;
        
    }
} 

@media only screen and (min-width: 2400px) {
    .infoviewingimage {
        align-self: center;
        
    }
    #nav-link {
        letter-spacing: 3px; 
    }
} 